// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

body {
    background-color: #1d1d24;
}

.title {
    width: 100% !important;
}

.title * {
    width: 100%;
}

.col-lg-5 {
    max-width: 400px;
    margin: auto;
}

p span {
    color: #fff;
    font-size: 16px;
}

p a {
    color: #007bff !important;
    text-decoration: underline;
}

a span {
    text-decoration: underline;
    cursor: pointer;
}