.tokyo_tm_modalbox_news {
    .descriptions .bigger,
    .date,
    .date a {
      color: #bbb;
    }
    .main_content {
      font-family: "Mulish";
      .icon .svg {
        filter: invert(1);
      }
      .news_share span {
        color: #fff;
      }
    }
  }

.tokyo_tm_modalbox_news {
  color: #bbb;
  font-family: "Mulish";
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  font-weight: 400;
}
  
.tokyo_tm_modalbox_news .box_inner,
.tokyo_tm_modalbox_about .box-inner {
  height: 75vh;
  width: 968px;
  overflow-y: auto;
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    width: 850px;
  }
  @media only screen and (min-width: 767px) and (max-width: 992px) {
    width: 730px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 530px;
    height: 500px;
  }
  @media only screen and (min-width: 350px) and (max-width: 575px) {
    width: 350px;
    height: 500px;
  }
  @media only screen and (min-width: 0px) and (max-width: 349px) {
    width: 300px;
    height: 500px;
  }
}
.tokyo_tm_modalbox_news.opened {
  opacity: 1;
  visibility: visible;
}
.tokyo_tm_modalbox_news .container {
  height: 100vh;
}

.tokyo_tm_modalbox_news.opened .box_inner {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}
.tokyo_tm_modalbox_news .close {
  position: fixed;
  left: 100%;
  top: 0px;
  margin-left: 40px;
  z-index: 111111;
}
.tokyo_tm_modalbox_news .close a {
  text-decoration: none;
  color: #fff;
}
.tokyo_tm_modalbox_news .close .svg {
  width: 50px;
  height: 50px;
}
.tokyo_tm_modalbox_news .description_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  overflow-y: auto;
  padding: 5%;
}
.tokyo_tm_modalbox_news .details {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.tokyo_tm_modalbox_news .description_wrap .image {
  position: relative;
  padding-top: 9.0px / 16.0px * 100%;
  z-index: -1;
  margin-bottom: 3%;
}
.tokyo_tm_modalbox_news .description_wrap .image img {
  height: 100%;
}
.tokyo_tm_modalbox_news .description_wrap .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tokyo_tm_modalbox_news .details .extra {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}
.tokyo_tm_modalbox_news .details .title {
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 9px;
  @media screen and (max-width: 991px) {
    line-height: 1.5;
  }
}
.tokyo_tm_modalbox_news .date {
  text-transform: uppercase;
  font-family: "Montserrat";
  font-size: 12px;
  color: #767676;
  font-style: italic;
}
.tokyo_tm_modalbox_news .date a {
  text-decoration: none;
  color: #767676;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tokyo_tm_modalbox_news .date a:hover {
  color: #e54b4b;
}
.tokyo_tm_modalbox_news .date span {
  position: relative;
  margin-left: 11px;
}
.tokyo_tm_modalbox_news .date span:before {
  position: absolute;
  content: "";
  margin-top: 0px;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  right: 100%;
  background-color: #939393;
  width: 1px;
  height: 9px;
  margin-right: 7px;
}
.tokyo_tm_news .main_content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -11;
  display: none;
}
body.modal {
  overflow-y: hidden;
}
.tokyo_tm_modalbox_news .main_content {
  width: 100%;
  float: left;
}
.tokyo_tm_modalbox_news .main_content .descriptions {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  // font-family: "Poppins";
}
.tokyo_tm_modalbox_news .main_content .descriptions .bigger {
  color: #888;
  font-size: 20px;
  margin-bottom: 33px;
}
.tokyo_tm_modalbox_news .main_content .descriptions p {
  margin-bottom: 22px;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  font-weight: 400;
  font-family: "Poppins";
}
.tokyo_tm_modalbox_news .main_content .quotebox {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  padding-left: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.tokyo_tm_modalbox_news .main_content .quotebox p {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 23px;
}
.tokyo_tm_modalbox_news .main_content .icon {
  position: absolute;
  left: 0px;
  top: 10px;
}
.tokyo_tm_modalbox_news .main_content .icon .svg {
  width: 40px;
  height: 40px;
  color: #000;
}
.tokyo_tm_modalbox_news .news_share {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
}
.tokyo_tm_modalbox_news .news_share span {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  padding-right: 20px;
  color: #000;
}
.tokyo_tm_modalbox_news .news_share ul {
  margin: 0px;
  list-style-type: none;
  position: relative;
  top: -2px;
}
.tokyo_tm_modalbox_news .news_share ul li {
  margin: 0px 12px 0px 0px;
  display: inline-block;
}
.tokyo_tm_modalbox_news .news_share ul li .svg {
  width: 15px;
  height: 15px;
}

.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: initial;
  border-radius: 4px;
  outline: none;
  background: #101010;
  border: none;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.close-modal {
  background: transparent;
  border: none;
  display: flex;
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 4;
  right: -50px;
  top: -32px;
  @media screen and (max-width: 1199px) {
    right: 0px;
    top: -60px;
  }
  @media screen and (max-width: 575px) {
    right: -10px;
    top: -37px;
  }
  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: absolute;
    filter: invert(1);
    transition: 0.3s;
    @media screen and (max-width: 575px) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    img {
      filter: invert(0.8);
    }
  }
}
